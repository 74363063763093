<template>
  <!-- 會員登入 -->
  <div class="member_main">
    <div class="member_main_container">
      <div class="member_login_logo">
        <img @click="goHome" src="../../assets/image/member_login_logo.png" alt />
      </div>
      <div class="member_login_title">學習管理中心</div>
      <div class="member_login_contianer">
        <div>
          <div class="s_login">
            <div class="member_login_input">
              <input
                class="account_icon"
                v-model="menegerInfo.userName"
                placeholder="輸入帳號"
                type="text"
              />
            </div>
            <div class="member_login_input">
              <input class="password_icon"
                v-model="menegerInfo.password" placeholder="輸入密碼" type="password" />
              <!-- <div class="password_get_container">
                <span class="password_get_open">忘記密碼？</span>
              </div> -->
            </div>
            <a>
              <div class="member_login_btn" @click="toLoginStaff">登入</div>
            </a>
            <div class="member_keep_login">
              <input v-model="menegerInfo.isKeepLogin " type="checkbox" value />
              <label for="s_keep_login">保持登入狀態</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 會員登入end -->
</template>
<script>
import { getStaffLogin } from "../../api/api";
export default {
  data() {
    return {
      menegerInfo: {}
    };
  },
  methods: {
    //返回首页
    goHome(){
      // this.$router.push("/index")
    },
    //客服登陆
    async toLoginStaff() {
      const res = await getStaffLogin(this.menegerInfo);
      if (res && res.code == 200) {
        window.localStorage.setItem("managerToken", res.data.token);
        this.$router.push("/manager");
      }
    }
  }
};
</script>
<style scoped>
@import "../../assets/css/manager_login.css";
</style>